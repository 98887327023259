import { LocationObject } from "/server/parse-location.js";

export async function toastStoreOpen(date) {

    var open = false;

    const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
    console.log('Delivery bool ' + deliveryBool);
    var diningBehavior = 'TAKE_OUT';
    if (deliveryBool == true) {
        diningBehavior = 'DELIVERY';
    }

    var orderSchedule = JSON.parse(localStorage.getItem('toastOrderSchedule'));
    if (orderSchedule == null) {

        const locationObject = await LocationObject();
        const restaurantGuid = locationObject.get('guid');

        const toastLocation = await Parse.Cloud.run('getToastLocation', {'restaurantGuid': restaurantGuid});
        localStorage.setItem('toastLocation', JSON.stringify(toastLocation.get('object')));

        const toastOrderSchedule = await Parse.Cloud.run('getToastOrderSchedule', {'restaurantGuid': restaurantGuid});
        localStorage.setItem('toastOrderSchedule', JSON.stringify(toastOrderSchedule.get('object')));
        orderSchedule = toastOrderSchedule.get('object');
    }

    const servicePeriods = orderSchedule.servicePeriods;
    console.log('Service periods ' + JSON.stringify(servicePeriods));
    
    const servicePeriod = servicePeriods.find(obj => obj.diningOptionBehavior === diningBehavior);
    if (servicePeriod != null) {

        const dayPeriods = servicePeriod.dayPeriods;
        
        const options = { weekday: 'long' };
        const dayCaps = date.toLocaleDateString('en-US', options).toUpperCase();

        const currentPeriod = dayPeriods.find(obj => obj.day === dayCaps);
        
        if (currentPeriod != null) {

            let timeRange = currentPeriod.timeRanges[0];
            
            let startTime = timeRange.start;
            let endTime = timeRange.end;

            const startDate = new Date(date);
            
            const startHour = Number(startTime.substring(0, 2));
            const startMinute = Number(startTime.substring(startTime.length - 2));

            startDate.setHours(startHour, startMinute, 0, 0);
            
            const endDate = new Date(date);
            const endHour = Number(endTime.substring(0, 2));
            const endMinute = Number(endTime.substring(endTime.length - 2));

            endDate.setHours(endHour, endMinute, 0, 0);
            
            const lastOrderConfiguration = orderSchedule.lastOrderConfiguration;
            if (lastOrderConfiguration == "UNTIL_PREPTIME_CUTOFF") {

                var cutoffKey = "deliveryPrepTime";
                if (diningBehavior == "TAKE_OUT") {
                    cutoffKey = "takeoutPrepTime";
                }
                const toastLocation = JSON.parse(localStorage.getItem('toastLocation'));
                if (toastLocation != null) {
                    const prepTime = toastLocation.prepTimes[cutoffKey];
                    let difference = endDate.getMinutes() - prepTime;
                    endDate.setMinutes(difference);
                }
            } 
            if (date > startDate && date < endDate) {
                return true;
            }
        }
    }
    return false;
}

export function getOpenTime(date) {

    const orderSchedule = JSON.parse(localStorage.getItem('toastOrderSchedule'));
    const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
    var diningBehavior = 'TAKE_OUT';
    if (deliveryBool) {
        diningBehavior = 'DELIVERY';
    }

    const servicePeriods = orderSchedule.servicePeriods;
    const servicePeriod = servicePeriods.find(obj => obj.diningOptionBehavior === diningBehavior);
    if (servicePeriod != null) {

        const dayPeriods = servicePeriod.dayPeriods;
        
        const options = { weekday: 'long' };
        const dayCaps = date.toLocaleDateString('en-US', options).toUpperCase();

        const currentPeriod = dayPeriods.find(obj => obj.day === dayCaps);
        
        if (currentPeriod != null) {

            let timeRange = currentPeriod.timeRanges[0];
            
            let startTime = timeRange.start;
            
            const startHour = Number(startTime.substring(0, 2));
            const startMinute = Number(startTime.substring(startTime.length - 2));

            const startDate = new Date(date);
            startDate.setHours(startHour, startMinute, 0, 0);

            return startDate;
        }
    }
}

export function getCloseTime(date) {

    const orderSchedule = JSON.parse(localStorage.getItem('toastOrderSchedule'));
    const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
    var diningBehavior = 'TAKE_OUT';
    if (deliveryBool) {
        diningBehavior = 'DELIVERY';
    }

    const servicePeriods = orderSchedule.servicePeriods;
    const servicePeriod = servicePeriods.find(obj => obj.diningOptionBehavior === diningBehavior);
    if (servicePeriod != null) {

        const dayPeriods = servicePeriod.dayPeriods;
        
        const options = { weekday: 'long' };
        const dayCaps = date.toLocaleDateString('en-US', options).toUpperCase();

        const currentPeriod = dayPeriods.find(obj => obj.day === dayCaps);
        
        if (currentPeriod != null) {

            let timeRange = currentPeriod.timeRanges[0];
            
            let endTime = timeRange.end;

            const endDate = new Date(date);
            const endHour = Number(endTime.substring(0, 2));
            const endMinute = Number(endTime.substring(endTime.length - 2));

            endDate.setHours(endHour, endMinute, 0, 0);

            return endDate;
        }
    }
}