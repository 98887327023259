import Parse from "/server/parse-server.js";
import locationId from "/server/parse-location.js";
import { LocationObject } from "/server/parse-location.js";
import { goToNextPage } from "/reusable/navigation.js";
import { getToastItem } from "/toast/toast-menu.js";
import { isMobileDevice } from "/reusable/device-type.js";
import { toastStoreOpen, getOpenTime, getCloseTime } from "/toast/toast-hours.js";

import analytics from "/firebase-analytics/analytics.js";
import { logEvent } from "firebase/analytics";

const currency = require('currency.js')

function removeHtmlExtension() {
    var url = window.location.href;
    var cleanUrl = url.replace(/\.html$/, '');
    var noIndex = cleanUrl.replace('index','');
    history.replaceState({}, document.title, noIndex);
}
removeHtmlExtension();

"use strict";
window.Webflow ||= [];
window.Webflow.push(async () => { 

    const progressModal = document.getElementById('progressModal');
    
    // MARK: - NAV PROFILE
    const currentUser = Parse.User.current();
    const navProfileButton = document.getElementById('navProfileButton');
    if (navProfileButton != null) {
        if (currentUser != null) {
            const anonymous = currentUser.get('anonymous');
            if (anonymous == true) {
                navProfileButton.style.display = 'none';
            }
        } else {
            navProfileButton.style.display = 'none';
        }
    }    

    // MARK: - NAV ORDER
    const deliveryAddress = JSON.parse(sessionStorage.getItem('deliveryAddress'));
    const location = JSON.parse(localStorage.getItem('location'));

    const navOrderDetailsButton = document.getElementById('navOrderDetailsButton');
    if (navOrderDetailsButton != null) {
        var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
        if (deliveryBool != null) {
            if (isMobileDevice() == false) {
                navOrderDetailsButton.style.display = 'block';
                if (deliveryBool) {
                    if (deliveryAddress != null) {
                        navOrderDetailsButton.innerText = 'DELIVER TO:\n' + deliveryAddress.addressLabel;
                    }
                } else {
                    if (location != null) {
                        navOrderDetailsButton.innerText = 'PICK UP AT:\n' + location.city.toUpperCase();
                    }
                }
            }
        }
    }

    var initialLoad = true;
    const detailsModal = document.getElementById('detailsModal');
    const detailsOrderTypeLabel = document.getElementById('detailsOrderTypeLabel');
    const detailsTopLabel = document.getElementById('detailsTopLabel');
    const detailsAddressLabel = document.getElementById('detailsAddressLabel');
    const detailsCityLabel = document.getElementById('detailsCityLabel');
    const detailsChangeTypeButton = document.getElementById('detailsChangeTypeButton');
    const detailsChangeAddressButton = document.getElementById('detailsChangeAddressButton');
    const detailsBottomButton = document.getElementById('detailsBottomButton'); 
    if (detailsOrderTypeLabel != null) {
        setOrderDetails();
        if (detailsChangeTypeButton != null) {
            detailsChangeTypeButton.addEventListener('click', async () => {
                var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
                deliveryBool = !deliveryBool;
                if (deliveryBool) {
                    if (deliveryAddress != null) {
                        sessionStorage.setItem('deliveryBool', 'true');
                        initialLoad = false;
                        detailsBottomButton.innerText = 'CHANGE BACK';
                        detailsChangeTypeButton.style.display = 'none';
                        setOrderDetails();
                        setUpOrderDetails();
                    } else {
                        goToNextPage('/order');
                    }
                } else {
                    sessionStorage.setItem('deliveryBool', 'false');
                    initialLoad = false;
                    detailsBottomButton.innerText = 'CHANGE BACK';
                    detailsChangeTypeButton.style.display = 'none';  
                    setOrderDetails();
                    setUpOrderDetails();
                }
            })
        }
        if (detailsChangeAddressButton != null) {
            detailsChangeAddressButton.addEventListener('click', async () => {
                goToNextPage('/order');
            })
        }
        if (detailsBottomButton != null) {
            detailsBottomButton.addEventListener('click', async () => {
                if (initialLoad) {
                    detailsModal.style.display = 'none';
                } else {
                    var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
                    deliveryBool = !deliveryBool;
                    sessionStorage.setItem('deliveryBool', JSON.stringify(deliveryBool));
                    initialLoad = true;
                    detailsBottomButton.innerText = 'CLOSE';
                    detailsChangeTypeButton.style.display = 'block';
                    setOrderDetails();
                    setUpOrderDetails();
                }
            })
        }
    }   

    // MARK: - FUTURE ORDER
    const dateModal = document.getElementById('dateModal');
    const orderDatePicker = document.getElementById('orderDatePicker');
    const orderTimePicker = document.getElementById('orderTimePicker');
    const chooseTimeButton = document.getElementById('chooseTimeButton');

    const orderSchedule = JSON.parse(localStorage.getItem('toastOrderSchedule'));

    var datePicker;
    var timePicker;

    function initializeDatePicker() {

        const now = new Date();

        var minimumDate = new Date(now);
        minimumDate.setMinutes(minimumDate.getMinutes() + 90);

        const closeDate = getCloseTime(minimumDate);
        if (minimumDate > closeDate) {
            const nextDay = new Date(now);
            nextDay.setDate(nextDay.getDate() + 1);
            minimumDate = nextDay;
        }

        const maximumDate = new Date(minimumDate);
        maximumDate.setDate(maximumDate.getDate() + 14);       
        
        //today.setMinutes(today.getMinutes() + 60);

        datePicker = flatpickr(orderDatePicker, {
            dateFormat: "l, M d",
            defaultDate: minimumDate,
            minDate: minimumDate,
            maxDate: maximumDate,
            onDayCreate: function(dObj, dStr, fp, dayElem) {
                if (dayElem.classList.contains("selected")) {
                    dayElem.style.backgroundColor = '#cf1d02'; // Change the background color to red for selected dates
                } else {
                    dayElem.style.backgroundColor = "initial"; // Reset the background color for non-selected dates
                }
            },
            onChange: function(selectedDates, dateStr, instance) {
                const selectedDate = selectedDates[0];
                setUpTimePicker(selectedDate);
            }
        });

        setUpTimePicker(minimumDate);

    }

    function setUpTimePicker(startDate) {

        var minimumTime = new Date(startDate);
        const startDateToday = isToday(minimumTime);
        if (startDateToday) {
            const openDate = getOpenTime(minimumTime);
            openDate.setMinutes(openDate.getMinutes() + 60);
            if (minimumTime < openDate) {
                minimumTime = openDate;
            }
        } else {
            // Add 90 minutes to the opening time
            minimumTime = getOpenTime(minimumTime);
            minimumTime.setMinutes(minimumTime.getMinutes() + 60);
        }

        const maximumTime = getCloseTime(startDate);

        timePicker = flatpickr(orderTimePicker, {
            enableTime: true,
            noCalendar: true,
            allowInput: false,
            dateFormat: "h:i K",
            defaultDate: minimumTime,
            minTime:  minimumTime,
            maxTime: maximumTime
            //defaultHour: today.getHours()
        });
    }

    function isToday(dateToCheck) {
        const today = new Date();
        const todayString = today.toDateString();
        const dateString = dateToCheck.toDateString();
        return todayString === dateString;
    }

    if (chooseTimeButton != null) {
        chooseTimeButton.addEventListener('click', async function() {
            progressModal.style.display = 'flex';
            const selectedTime = timePicker.selectedDates[0];
            const open = await toastStoreOpen(selectedTime);
            if (open == true) {
                sessionStorage.setItem('futureDate', selectedTime.toISOString());
                setMobileOrderDetails();
                dateModal.style.display = 'none';
            } else {
                alert('Store will be closed please try another time or day');
            }
            progressModal.style.display = 'none';
        });
    }

    function setOrderDetails() {
        if (navOrderDetailsButton != null) {
            var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
            if (deliveryBool != null) {
                if (deliveryBool == true) {
                    if (deliveryAddress != null) {
                        detailsOrderTypeLabel.innerText = 'DELIVERY';
                        detailsTopLabel.innerText = 'DELIVERY ADDRESS';
                        detailsAddressLabel.innerText = deliveryAddress.addressLabel.toUpperCase();
                        detailsCityLabel.innerText = deliveryAddress.city.toUpperCase() + ', ' + deliveryAddress.stateCode + ' ' + deliveryAddress.postalCode;
                        navOrderDetailsButton.innerText = 'DELIVER TO:\n' + deliveryAddress.addressLabel.toUpperCase();
                    }
                } else {
                    if (location != null) {
                        detailsOrderTypeLabel.innerText = 'PICK UP';
                        detailsTopLabel.innerText = 'PICK UP ADDRESS';
                        detailsAddressLabel.innerText = location.address.toUpperCase();
                        detailsCityLabel.innerText = location.city.toUpperCase() + ', ' + location.state + ' ' + location.zipcode;
                        navOrderDetailsButton.innerText = 'PICK UP AT:\n' + location.city.toUpperCase();
                    }
                }
            }
        }
    }

    const mobileOrderSection = document.getElementById('mobileOrderSection');
    const mobileOrderTypeButton = document.getElementById('mobileOrderTypeButton');
    const mobileAddressLabel = document.getElementById('mobileAddressLabel');
    const mobileOrderTimeButton = document.getElementById('mobileOrderTimeButton');

    if (mobileOrderTypeButton != null) {
        mobileOrderTypeButton.addEventListener('click', async () => {

        });
    }

    if (mobileOrderTimeButton != null) {
        mobileOrderTimeButton.addEventListener('click', async () => {
            if (dateModal != null) {
                dateModal.style.display = 'flex';
                initializeDatePicker();
            }
        });
    }

    function setMobileOrderDetails() {
        if (mobileOrderSection != null) {
            var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
            if (deliveryBool != null) {

                const homeStartOrderSection = document.getElementById("homeStartOrderSection");
                if (homeStartOrderSection != null) { 
                    homeStartOrderSection.style.display = 'none';
                }
                mobileOrderSection.style.display = 'block';
                if (deliveryBool == true) {
                    if (deliveryAddress != null) {
                        mobileOrderTypeButton.innerText = 'DELIVERY';
                        var addressString = deliveryAddress.addressLabel.toUpperCase() + '\n' + deliveryAddress.city.toUpperCase() + ', ' + deliveryAddress.stateCode + ' ' + deliveryAddress.postalCode;
                        mobileAddressLabel.innerText = addressString;
                    }
                } else {
                    if (location != null) {
                        mobileOrderTypeButton.innerText = 'PICK UP';
                        var addressString = location.address.toUpperCase() + '\n' + location.city.toUpperCase() + ', ' + location.state + ' ' + location.zipcode;
                        mobileAddressLabel.innerText = addressString;
                    }
                }

                // Set time
                const futureDateString = sessionStorage.getItem('futureDate');
                if (futureDateString != null) {
                    const futureDate = new Date(futureDateString);
                    const formattedDate = futureDate.toLocaleDateString('en-US', {
                        weekday: 'long', 
                        month: 'short', 
                        day: 'numeric'
                    });

                    const options = {hour: '2-digit', minute: '2-digit', hour12: true };
                    const hourString = futureDate.toLocaleString('en-US', options);
                    mobileOrderTimeButton.innerText = formattedDate + ' - ' + hourString;
                } else {
                    mobileOrderTimeButton.innerText = 'ASAP';
                }
            }
        }
    }

    if (isMobileDevice() == true) {
        setMobileOrderDetails();
    }

    function setUpOrderDetails() {

        var deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
        const deliveryAddress = JSON.parse(sessionStorage.getItem('deliveryAddress'));
        const pickupAddress = JSON.parse(sessionStorage.getItem('pickupAddress'));
        const location = JSON.parse(localStorage.getItem('location'));

        const locationLabel = document.getElementById('locationLabel');
        const storeLabel = document.getElementById('storeLabel');
        const phoneNumberButton = document.getElementById('phoneNumberButton');
        const pickUpIcon = document.getElementById('pickUpIcon');
        const deliveryIcon = document.getElementById('deliveryIcon');

        if (locationLabel != null) {
            if (deliveryBool != null) {
                if (deliveryBool) {
                    if (deliveryAddress != null) {
                        const address = deliveryAddress.addressLabel.toUpperCase();
                        const city = deliveryAddress.city.toUpperCase();
                        const stateCode = deliveryAddress.stateCode.toUpperCase();
                        const postalCode = deliveryAddress.postalCode.toUpperCase();
                        locationLabel.innerText = address + '\n' + city + ', ' + stateCode + ' ' + postalCode;
                        storeLabel.innerText = location.city.toUpperCase();
                        deliveryIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce7960111dd6cc4d6c_radio-button-selected.png';
                        pickUpIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce243a44d1eee4ad81_radio-button.png';
                        
                    } else {
                        sessionStorage.setItem('deliveryBool', 'false');
                        if (location != null) {
                            locationLabel.innerText = pickupAddress.postalCode;
                            storeLabel.innerText = location.city.toUpperCase();
                            pickUpIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce7960111dd6cc4d6c_radio-button-selected.png';
                            deliveryIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce243a44d1eee4ad81_radio-button.png';
                        }
                    }
                } else {
                    if (location != null) {
                        locationLabel.innerText = pickupAddress.postalCode;
                        storeLabel.innerText = location.city.toUpperCase();
                        pickUpIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce7960111dd6cc4d6c_radio-button-selected.png';
                        deliveryIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/675a12ce243a44d1eee4ad81_radio-button.png';
                        
                    }
                }
            }
        }
    }

    // MARK: - NAV REWARDS
    const navRewardsButton = document.getElementById('navRewardsButton');
    if (navRewardsButton != null) {
        navRewardsButton.addEventListener('click', async () => {
            if (currentUser != null) {
                const anonymous = currentUser.get('anonymous');
                if (anonymous == true) {
                    goToNextPage('/rewards-program');
                } else {
                    goToNextPage('/rewards');
                }
            } else {
                goToNextPage('/rewards-program');
            }
        })
    }

    // MARK: - NAV LOGIN
    const navLoginButton = document.getElementById('navLoginButton');
    if (navLoginButton != null) {
        if (currentUser != null) {
            const anonymous = currentUser.get('anonymous');
            if (anonymous == false) {
                const firstname = currentUser.get('firstname');
                navLoginButton.innerText = 'HEY ' + firstname.toUpperCase() + ',\nLOGOUT'
            }
        }

        navLoginButton.addEventListener('click', async () => {
            const currentUser = Parse.User.current();
            if (currentUser != null) {
                const anonymous = currentUser.get('anonymous');
                if (anonymous == false) {
                    progressModal.style.display = 'flex';
                    try {
                        await Parse.User.logOut();
                        localStorage.removeItem('location');
                        sessionStorage.removeItem('pickupAddress');
                        sessionStorage.removeItem('deliveryAddress');
                        sessionStorage.removeItem('deliveryBool');
                        goToNextPage('/');
                    } catch(e) {
                        alert('Logout failed ' + e.message);
                    }
                    progressModal.style.display = 'none';
                } else {
                    if (loginModal != null) {
                        loginModal.style.display = 'flex';
                    }
                }
            } else {
                // Show the login popup
                if (loginModal != null) {
                    loginModal.style.display = 'flex';
                }
            }
        });
    }

    // MARK; - LOGIN FORM
    const loginModal = document.getElementById("loginModal"); 
    const loginEmailField = document.getElementById("loginEmailField");
    const loginPasswordField = document.getElementById("loginPasswordField");
    const loginForgotButton = document.getElementById("loginForgotButton");
    const loginFormButton = document.getElementById("loginButton");

    $("#loginForm").submit(() => { return false; });

    if (loginForgotButton != null) {
        loginForgotButton.addEventListener('click', () => {
            loginModal.style.display = 'none';
            forgotModal.style.display = 'flex';
        });
    }

    if (loginFormButton != null) {
        loginFormButton.addEventListener('click', async () => {
            const email = loginEmailField.value;
            const password = loginPasswordField.value;
            const error = verifyLoginInfo(email, password);
            if (error != '') {
                alert(error);
            } else {
                loginUser(email, password);
            }
        });
    }

    function verifyLoginInfo(email, password) {
        var error = '';
        if (password.length <= 6) {
            error = 'Password must contain more than 6 characters';
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            error = 'Enter a valid email addres';
        }
        return error;
    }

    async function loginUser(email, password) {
        var userData = {'email':email,'password':password, 'locationid': locationId};
        progressModal.style.display = 'flex';
        try {
            const loginUser = await Parse.Cloud.run('loginUserAccount', userData);
            const sessionToken = loginUser.get('sessionToken');
            await Parse.User.become(sessionToken);
            logEvent(analytics, 'login', {method: 'Email'});
            window.location.reload(); 
        } catch(e) {
            alert(e.message);
        }
        progressModal.style.display = 'none';
    }

    // MARK: - FORGOT FORM
    const forgotModal = document.getElementById("forgotModal"); 
    const forgotLoginButton = document.getElementById("forgotLoginButton");
    const forgotEmailField = document.getElementById("forgotEmailField");
    const forgotButton = document.getElementById("forgotButton");

    $("#forgotForm").submit(() => { return false; });

    if (forgotLoginButton != null) {
        forgotLoginButton.addEventListener('click', () => {
            forgotModal.style.display = 'none';
            loginModal.style.display = 'flex';
        });
    }   

    if (forgotButton != null) {
        forgotButton.addEventListener('click', () => {
            const email = forgotEmailField.value;
            const error = verifyForgotInfo(email);
            if (error != '') {
                alert(error);
            } else {
                resetPassword(email);
            }
        });
    }

    function verifyForgotInfo(email) {
        var error = '';
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            error = 'Enter a valid email addres';
        }
        return error;
    }

    async function resetPassword(email) {
        progressModal.style.display = 'flex';
        const request = { 'email': email };
        try {
            await Parse.Cloud.run('resetUserPassword', request);
            forgotEmailField.value = '';
            forgotModal.style.display = 'none';
            alert('Check email for a password reset link');
        } catch(e) {
            alert(e.message);
        }
        progressModal.style.display = 'none';
    }

    // MARK: - CART / BOX
    const dropDownBoxButton = document.getElementById('dropDownBoxButton');
    const boxCloseButton = document.getElementById('boxCloseButton');
    const boxDropDown = document.getElementById('boxDropDown');

    const boxSubtotalLabel = document.getElementById('boxSubtotalLabel');

    var boxOpen = false;

    if (dropDownBoxButton != null) {
        dropDownBoxButton.addEventListener('click', () => {
            boxOpen = !boxOpen
            openCloseBox();
        });
    }

    if (boxCloseButton != null) {
        boxCloseButton.addEventListener('click', () => {
            boxOpen = false;
            openCloseBox();
        });
    }

    function openCloseBox() {
        if (boxOpen == true) { boxDropDown.style.display = 'flex'; } else { boxDropDown.style.display = 'none'; }
    }

    const navItemCountLabel = document.getElementById('navItemCountLabel');
    const boxEmptyDiv = document.getElementById('boxEmptyDiv');
    const boxItemsDiv = document.getElementById('boxItemsDiv');

    const boxStartOrderButton = document.getElementById('boxStartOrderButton');
    if (boxStartOrderButton != null) {
        boxStartOrderButton.addEventListener('click', () => {
            const deliveryBool = sessionStorage.getItem('deliveryBool');
            if (deliveryBool != null) {
                goToNextPage('/menu');
            } else {
                goToNextPage('/order');
            }
        });
    }

    const boxCheckOutButton = document.getElementById('boxCheckOutButton');
    if (boxCheckOutButton != null) {
        boxCheckOutButton.addEventListener('click', () => {
            const deliveryBool = sessionStorage.getItem('deliveryBool');
            if (deliveryBool != null) {
                goToNextPage('/box');
            } else {
                goToNextPage('/order');
            }
        });
    }

    var selections = [];

    async function setCartItems() {

        const homeCartDiv = document.getElementById("homeCartDiv");
        const homeCartCountLabel = document.getElementById("homeCartCountLabel");

        const currentUser = Parse.User.current();
        if (currentUser != null) {

            progressModal.style.display = 'flex';
            await currentUser.fetch();
            progressModal.style.display = 'none';

            const orderCheck = currentUser.get('orderCheck');

            const checks = orderCheck.checks;
            if (checks != null) {

                selections = checks[0].selections;
             
                if (selections.length == 0) {
                    navItemCountLabel.innerText = '0';
                    boxItemsDiv.style.display = 'none';
                    boxEmptyDiv.style.display = 'flex';

                    if (homeCartCountLabel != null) {
                        homeCartDiv.style.display = 'none';
                    }

                } else {
                    var quantity = 0;
                    for (const selection of selections) {
                        quantity += selection.quantity;
                    }
                    navItemCountLabel.innerText = '' + quantity;
                    boxItemsDiv.style.display = 'flex';
                    boxEmptyDiv.style.display = 'none';

                    if (homeCartCountLabel != null) {
                        if (isMobileDevice() == false) {
                            homeCartDiv.style.display = 'flex';
                            homeCartCountLabel.innerText = '( ' + quantity + ' )';
                        } else {
                            homeCartDiv.style.display = 'none';
                        }
                    }

                    setupCartListView();
                    setPriceLabel();
                }
            }

            
        } else {
            navItemCountLabel.innerText = '0';
            boxItemsDiv.style.display = 'none';
            boxEmptyDiv.style.display = 'flex';

            if (homeCartCountLabel != null) {
                homeCartDiv.style.display = 'none';
            }
        }
    }

    async function setPriceLabel() {
        const currentUser = Parse.User.current();
        const checkJson = currentUser.get("orderCheck");
        const check = checkJson["checks"][0];
        var subtotal = check.amount;

        const locationObject = await LocationObject();

        let discounts = check.appliedDiscounts;
        if (discounts.length > 0) {
            const discount = discounts[0];
            const discountAmount = discount.discountAmount;
            subtotal += discountAmount;
        }
        
        // Set up service fee
        const serviceCharges = check.appliedServiceCharges;

        const serviceGuid = locationObject.get("serviceChargeGuid");
        const serviceCharge = serviceCharges.find(obj => obj.serviceCharge.guid === serviceGuid);
        if (serviceCharge != null) {
            const serviceAmount = serviceCharge.chargeAmount;
            subtotal -= serviceAmount;
        }
        
        // Set up delivery fee
        const deliveryFeeGuid = locationObject.get("deliveryFeeGuid");
        const deliveryCharge = serviceCharges.find(obj => obj.serviceCharge.guid === deliveryFeeGuid);
        if (deliveryCharge != null) {
            const deliveryAmount = deliveryCharge.chargeAmount;
            subtotal -= deliveryAmount;
        }

        boxSubtotalLabel.innerText = currency(subtotal).format();
    }

    async function setupCartListView() {

        const boxCell = document.querySelector('[box="cell"]');
        const boxParent = boxCell.parentNode;

        const boxCells = [...document.querySelectorAll('[box="cell"]')];

        for (let i = 0; i < selections.length; i++) {

            const newBoxCell = i === 0 ? boxCells[0] : boxCells[0].cloneNode(true);

            const selection = selections[i];
            const menuGroupGuid = selection.itemGroup.guid;
            const itemGuid = selection.item.guid;

            const itemPhoto = newBoxCell.querySelector('[box="itemPhoto"]');

            const menuItem = await getToastItem(menuGroupGuid, itemGuid);
            if (menuItem != null) {
                const imageUrl = menuItem.image;
                if (imageUrl != null) {
                    itemPhoto.style.display = 'block';
                    itemPhoto.src = imageUrl;
                } else {
                    itemPhoto.style.display = 'none';
                }
            } else {
                itemPhoto.style.display = 'none';
            }

            const quantityLabel = newBoxCell.querySelector('[box="quantityLabel"]');
            quantityLabel.innerText = '' + selection.quantity;

            const nameLabel = newBoxCell.querySelector('[box="foodNameLabel"]');
            nameLabel.innerText = selection.displayName;

            const priceLabel = newBoxCell.querySelector('[box="priceLabel"]');
            const totalPrice = selection.preDiscountPrice; 
            if (totalPrice == 0.0) {
                priceLabel.innerText = "FREE"
            } else {
                priceLabel.innerText = currency(totalPrice).format();
            }

            const modificationsLabel = newBoxCell.querySelector('[box="modificationsLabel"]');
            const selectedModifiers = selection.modifiers;
            var modnames = [];
            
            for (const selectedModifier of selectedModifiers) {
                
                const name = selectedModifier.displayName;
                modnames.push(name);
                
                const nestedMods = selectedModifier.modifiers;
                if (nestedMods.length > 0) {
                    for (const nested of nestedMods) {
                        const name = nested.displayName;
                        modnames.push(name);
                    }
                }
            }
            
            const mods = modnames.join('\n');
            modificationsLabel.innerText = mods

            const deleteBoxButton = newBoxCell.querySelector('[box="removeButton"]');
            deleteBoxButton.addEventListener('click', async () => {
                progressModal.style.display = 'flex';
                const locationObject = await LocationObject();
                const rewardGuid = locationObject.get("rewardGroupGuid");
                if (menuGroupGuid == rewardGuid) {
                    alert("Reward items can't be deleted");
                } else {
                    try {
                        await Parse.Cloud.run('removeToastSelection', {'index': i, 'locationid': locationId});
                        const boxCells = [...document.querySelectorAll('[box="cell"]')];
                        var index = 0;
                        for (const boxCell of boxCells) {
                            if (index != 0) {
                                boxCell.remove();
                            }
                            index += 1;
                        }
                        setCartItems();
                    } catch (e) {
                        alert(e.message);
                    }
                }
                progressModal.style.display = 'none';
            });

            boxParent.appendChild(newBoxCell);
        }
    }

    if (boxItemsDiv != null) {
        setCartItems();
    }

    // MARK: - STRIPE CHECK
    async function checkStripeAccount() {
        const currentUser = Parse.User.current();
        if (currentUser != null && currentUser.get('anonymous') == false) {
            const stripeid = currentUser.get('stripeid');
            if (stripeid == null) {
                await Parse.Cloud.run('addStripeCustomer', {});
                await currentUser.fetch();
            }
        }
    }
    checkStripeAccount();

    // MARK: - FOOTER 
    const footerInstagramButton = document.getElementById('footerInstagramButton');
    const footerFacebookButton = document.getElementById('footerFacebookButton');
    footerInstagramButton.addEventListener('click', function() {
        window.open('https://www.instagram.com/giannispizzeria/?hl=en', '_blank');
    });
    footerFacebookButton.addEventListener('click', function() {
        window.open('https://www.facebook.com/giannispizzeria1/', '_blank');
    });

});