import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_KEY,
  authDomain: "giannis-pizza-b68ea.firebaseapp.com",
  projectId: "giannis-pizza-b68ea",
  storageBucket: "giannis-pizza-b68ea.firebasestorage.app",
  messagingSenderId: "99701238247",
  appId: "1:99701238247:web:63674fe6767065b9397542",
  measurementId: "G-QSCGMYN6T5"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics;