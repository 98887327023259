import Parse from "/server/parse-server.js";
import { LocationObject } from "/server/parse-location.js";

export function filterVisibility(items) {
    const filteredData = items.filter(item => {
        return item.visibility.includes('TOAST_ONLINE_ORDERING');
    });
    return filteredData;
}

export function getStockStatus(itemGuid, stocks) {
    var stockJson = {};
    stockJson.status = "IN_STOCK";
    const stock = stocks.find(obj => obj.guid === itemGuid);
    if (stock != null) {
        return stock;
    }
    return stockJson;
}

function storeMenuData(toastMenuObject) {
    const menus = filterVisibility(toastMenuObject.menus);
    localStorage.setItem('toastMenus', JSON.stringify(menus));
    const modifierGroups = toastMenuObject.modifierGroupReferences;
    localStorage.setItem('toastModifierGroups', JSON.stringify(modifierGroups));
    const modifiers = toastMenuObject.modifierOptionReferences;
    localStorage.setItem('toastModifiers', JSON.stringify(modifiers));
}

export async function getToastItem(groupGuid, itemGuid) {
    var menus = JSON.parse(localStorage.getItem('toastMenus'));
    if (menus == null) {
        const locationObject = await LocationObject();
        const toastGuid = locationObject.get('guid');
        const parseMenu = await Parse.Cloud.run('loadToastMenu', {'restaurantGuid': toastGuid});
        const toastMenuObject = parseMenu.get('object');
        storeMenuData(toastMenuObject);
        menus = filterVisibility(toastMenuObject.menus);
    }
    if (menus != null) {
        for (const menu of menus) {
            const groups = menu.menuGroups;
            const group = groups.find(obj => obj.guid === groupGuid);
            if (group != null) {
                const items = group.menuItems;
                const item = items.find(obj => obj.guid === itemGuid);
                if (item != null) {
                    return item;
                }
            }
        }
    }
    return null;
}

export async function getToastGroup(groupGuid) {
    var menus = JSON.parse(localStorage.getItem('toastMenus'));
    if (menus == null) {
        const locationObject = await LocationObject();
        const toastGuid = locationObject.get('guid');
        const parseMenu = await Parse.Cloud.run('loadToastMenu', {'restaurantGuid': toastGuid});
        const toastMenuObject = parseMenu.get('object');
        storeMenuData(toastMenuObject);
        menus = filterVisibility(toastMenuObject.menus);
    }
    if (menus != null) {
        for (const menu of menus) {
            const groups = menu.menuGroups;
            const group = groups.find(obj => obj.guid === groupGuid);
            if (group != null) {
                return group;
            }
        }
    }
    return null;
}