import Parse from "/server/parse-server.js";

var locationId = "qNjOs9AgFj";
const location = JSON.parse(localStorage.getItem('location'));
if (location != null) {
    const id = location.id;
    if (id != null) {
        locationId = id;
    }
}

const currentUser = Parse.User.current();
if (currentUser != null) {
    const locId = currentUser.get('locationid');
    if (locId != null) {
        locationId = locId;
    }
}

/*
const id = localStorage.getItem('locationid');
if (id != null) {
    locationId = id;
}
const currentUser = Parse.User.current();
if (currentUser != null) {
    locationId = currentUser.get('locationid');
}*/
export default locationId;

export async function LocationObject() {
    if (locationId != null) {
        const location = await Parse.Cloud.run('loadLocation', {'locationid': locationId});
        return location;
    }
}

